import { FC, useCallback, useEffect, useRef, useState } from "react";
import { DEFAULT_OFFSET } from "../../../constants";
import { TempleWithCount } from "../../../domain/models/Temple";
import { Payment, PaymentWithCount } from "../../../domain/models/Payment";
import { PaymentSortFields } from "../../../enums/payment-sort-fields-enum";
import { TempleSortFields } from "../../../enums/temple-sort-fields-enum";
import paymentServiceFactory from "../../../services/PaymentServiceImpl";
import templeServiceFactory from "../../../services/TempleServiceImpl";
import PaymentHeader from "./PaymentHeader";
import PaymentTable from "./PaymentTable";
import { useSortingColumns } from "../../../tools/custom-hooks/table-hooks/useSortingColumns";
import { SortingOrder } from "../../../enums/sorting-order-enum";
import AdminPagePagination from "../AdminPagePagination";
import BaseChart from "../charts/BaseChart";
import { useEntityChartWithPrevious } from "../../../tools/custom-hooks/chart-hooks/useEntityChartWithPrevious";
import { TransactionType } from "../../../enums/transaction-type-enum";
import { ChartsParams } from "../../../types/charts/ChartsParams";
import { PaymentChartsFields } from "../../../enums/entity-fields/payment-fields-enum";
import { Translate } from "../../../types/Translate";
import { withTranslation } from "react-i18next";
import chartServiceFactory from "../../../services/ChartServiceImpl";
import { TempleStatistic } from "../../../domain/models/TempleStatistic";
import { templeStatisticServiceFactory } from "../../../services/TempleStatisticServiceImpl";
import { useAuth } from "../../../context/AuthProvider";

interface BasePaymentsProps {
    t: Translate;
    templeId?: string;
    payoutId?: string;
    isSuperAdminUser?: boolean;
    isTemplePayments?: boolean;
    additionalChartClassName?: string;
    additionalTableClassName?: string;
    disableCharts?: boolean;
    setPaymentsCallback?: (data: Payment[]) => void;
}

const chartService = chartServiceFactory();

const BasePayments: FC<BasePaymentsProps> = (props) => {
    const [payments, setPayments] = useState<PaymentWithCount>();
    const [temples, setTemples] = useState<TempleWithCount>();
    const [statistic, setStatistic] = useState<TempleStatistic>();

    const [visibleChart, setVisibleChart] = useState<boolean>(false);
    const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.DONATE);

    const paymentsTableRef = useRef<HTMLDivElement>(null);
    const columnsHandlers = useSortingColumns(SortingOrder.ASC);

    const { user } = useAuth();

    const paginationCallback = useCallback(
        async (currentPage: number, pageSize: number) => {
            const paymentService = paymentServiceFactory();
            const templeService = templeServiceFactory();

            const paymentsPagedData = await (props.templeId
                ? paymentService.getTemplePayments(
                      props.templeId,
                      currentPage,
                      pageSize,
                      DEFAULT_OFFSET,
                      columnsHandlers.currentActiveColumn as PaymentSortFields,
                      columnsHandlers.sortingOrder,
                      false,
                      props.payoutId
                  )
                : paymentService.getPayments(
                      currentPage,
                      pageSize,
                      DEFAULT_OFFSET,
                      columnsHandlers.currentActiveColumn as PaymentSortFields,
                      columnsHandlers.sortingOrder,
                      props.payoutId
                  ));

            const templeIds = paymentsPagedData.rows.map((payment) => payment.templeId).filter((templeId) => templeId);

            const templesPagedData = await (!props.isSuperAdminUser
                ? templeService.getCachedLatestTemples(
                      currentPage,
                      pageSize,
                      DEFAULT_OFFSET,
                      TempleSortFields.NAME,
                      columnsHandlers.sortingOrder,
                      false,
                      templeIds
                  )
                : templeService.getCachedTemples(
                      currentPage,
                      pageSize,
                      DEFAULT_OFFSET,
                      TempleSortFields.NAME,
                      columnsHandlers.sortingOrder,
                      templeIds
                  ));

            paymentService.setTemplesToPayments(paymentsPagedData.rows, templesPagedData.rows);

            setPayments(paymentsPagedData);
            setTemples(templesPagedData);

            props.setPaymentsCallback && props.setPaymentsCallback(paymentsPagedData.rows);
        },
        [columnsHandlers, props.isSuperAdminUser, props.templeId]
    );

    const paymentsChartsDataCallback = useCallback(
        async (chartsParams: ChartsParams) => {
            return paymentServiceFactory().getPaymentCharts(
                chartsParams,
                PaymentChartsFields.AMOUNT,
                transactionType,
                props.templeId
            );
        },
        [transactionType, props.templeId]
    );

    const charts = useEntityChartWithPrevious(visibleChart, paymentsChartsDataCallback);

    const transactionTypeHandler = useCallback(
        (value: TransactionType) => {
            setTransactionType(value);
            charts.currentPeriodChart.applyChartsParams();
            charts.prevPeriodChart.applyChartsParams();
        },
        [charts]
    );

    useEffect(() => {
        const uploadStatisticData = async () => {
            setStatistic(await templeStatisticServiceFactory().getTempleStatisticByTempleId(props.templeId));
        };

        if (!statistic && user?.isAdmin && props.templeId) {
            uploadStatisticData();
        }
    }, [props.templeId, statistic, user]);

    return (
        <>
            {payments && (
                <PaymentHeader
                    totalRows={payments.totalRows}
                    templeStatistic={statistic}
                    visibleChart={visibleChart}
                    setVisibleChart={setVisibleChart}
                    currentPeriodChart={charts.currentPeriodChart}
                    prevPeriodChart={charts.prevPeriodChart}
                    transactionType={transactionType}
                    transactionTypeHandler={transactionTypeHandler}
                    disableChart={props.disableCharts}
                />
            )}
            {!visibleChart ? (
                <>
                    {temples && payments && (
                        <PaymentTable
                            payments={payments.rows}
                            isSuperAdminUser={props.isSuperAdminUser}
                            isTemplePayments={props.isTemplePayments}
                            paymentsTableRef={paymentsTableRef}
                            sortingColumnsHandlers={columnsHandlers}
                            additionalTableClassName={props.additionalTableClassName}
                        />
                    )}
                    <div className="church__pagination__admin__block">
                        <AdminPagePagination
                            additionalPaginationClass="search__data__pagination"
                            pagesCount={payments?.totalPages}
                            wrapperRef={paymentsTableRef}
                            data={payments}
                            reloadCache={columnsHandlers.reloadCache}
                            setData={setPayments}
                            setReloadCache={columnsHandlers.setReloadCache}
                            serviceCallback={paginationCallback}
                        />
                    </div>
                </>
            ) : (
                <BaseChart
                    title={props.t(`transactionType.${transactionType}`)}
                    periodType={charts.currentPeriodChart.getPeriodType()}
                    currentPeriod={{
                        label: chartService.getChartLabel(props.t, charts.currentPeriodChart.chartsParams),
                        data: charts.currentPeriodChart.chartsData,
                    }}
                    prevPeriod={{
                        label: chartService.getChartLabel(props.t, charts.prevPeriodChart.chartsParams),
                        data: charts.prevPeriodChart.chartsData,
                    }}
                    additionalChartClassName={props.additionalChartClassName}
                />
            )}
        </>
    );
};

export default withTranslation()(BasePayments);
